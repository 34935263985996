<template lang="pug">
  .team
    section.hero
      .hero__container
        h1.hero__title.title
          | Дізнайтесь більше про
          |
          span нашу команду
        p.hero__desc Знайомтесь із командою професіональних тренерів «Vidar»
        h2.hero__sub-title Наша команда
    section.trainers
      .trainers__container
        ul.trainers__list
          li.trainers__item(
            v-for="(trainerItem, trainerIndex) in trainersData"
            :key="trainerIndex"
          )
            .trainers__img
              img(
                :src="trainerItem.image"
                :alt="trainerItem.name"
              )
            .trainers__info
              h3.trainers__title {{ trainerItem.name }}
              .trainers__text
                p.trainers__position {{ trainerItem.position }}
                p(
                  v-for="(paragraphItem, paragraphIndex) in trainerItem.paragraphs"
                  :key="paragraphIndex"
                ) {{ paragraphItem }}
                p.trainers__quote(v-if="trainerItem.quote") {{ trainerItem.quote }}
              .trainers__footer
                .trainers__table
                  table
                    tbody
                      tr
                        td Напрямок:
                        td {{ trainerItem.direction }}
                      tr
                        td Контакти:
                        td.trainers__social
                          a(:href="trainerItem.instagram")
                            img(
                              :src="Instagram"
                              alt="instagram"
                            )
                          a(:href="trainerItem.facebook")
                            img(
                              :src="Facebook"
                              alt="facebook"
                            )
                a.link(href="tel:+380687835335") Запис
</template>

<script>
// constans
import { trainersData } from '@/configs/constants'
// images
import Instagram from '@/assets/images/social/instagram-black.svg'
import Facebook from '@/assets/images/social/facebook-black.svg'
export default {
  name: 'Team',
  data () {
    return {
      Instagram,
      Facebook,
      trainersData: trainersData
    }
  }
}
</script>
